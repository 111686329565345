.button {
  width: fit-content;
  border-radius: 5px;
  padding: 15px 20px;
  transition: all .3s ease-in-out;
  display: block;
  align-items: center;
  justify-content: center;
  display: flex;

  &--main{
    border: 1px solid $color-main;
    color: $color-main;

    &:hover {
      background-color: $color-main;
      color: white;
    }
  }

  &--reverse{
    display: block;
    color: white;
    border: 1px solid $color-main;
    border-radius: 5px;
    margin-top: 13px;
    padding: 1vw 2vw;
    width: 100%;
    line-height: 0;
    align-items: center;
    justify-content: center;
    display: flex;

    &:hover {
      border: 1px solid $color-extra;
      background-color: $color-extra ;
      border: 1px solid transparent;
    }
  } 
}
