.catalog-card {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 8px;
  background-color: #fff;

  transition: all .4s ease-in-out;
  &:hover{
    -webkit-box-shadow: 0px 0px 15px 2px $color-shadow; 
  }
  @include mq($from: xs, $until: md){
    flex-direction: row;
  }

  &--attraction{
    background-color:#fff;
    &:hover{
      background-color: $color-bg-extra;
      .catalog-card__more{
        opacity: 1;
        background-color: $color-bg;
      }
    }
  }

  &__img {
    height: 21vw;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 8px 8px 0 0;
    filter: saturate(.7);
    transition: filter .3s ease-in-out;

    @include mq($from: xs, $until: lg){
      height: 24vw;
    }
    @include mq($from: xs, $until: md){
      width: 55%;
      min-height: 30vw;
      border-radius: 8px 0 0 8px;
    }
    @include mq($from: xs, $until: sm){
      min-height: 45vw;
    }
    @include mq($until: xs){
        min-height: 50vw;
        border-radius: 8px 8px 0 0;
    }
   &--atract{
    height: 14vw;
      @include mq($until: xl){
        height: 18vw;
      }
   }
  }
  &__content{
    padding: 2vw 30px 2vw 30px;
    display: flex;
    flex-direction: column;
    // height: calc(100% - 17vw);
    @include mq($until: xxl){
      padding: 3vw 30px 3vw 30px;
      padding: 1.3vw 1.3vw;
    }
    @include mq($until: lg){
      justify-content: center;
      padding: 2vw;
      text-align: center;
    }
    @include mq($until: md){
      width: 60%;
      min-height: 30vw;
      padding: 2vw 2vw 2vw 4vw;
      text-align: left;
    }
    @include mq($until: sm){
      width: 50%;
      min-height: 45vw;
    }
    @include mq($until: xs){
      width: 100%;
      align-items: center;
    }
  }
  &__head{
    font-size: 0.9vw;
    font-weight: $fw-semi-bold;
    margin-bottom: 0;
    transition: color .3s ease-in-out;
    @include mq($until: xl){
      font-size: 1.6vw;
    }
    @include mq($until: lg){
      font-size: 1.8vw;
    }
    @include mq($until: sm){
      font-size: 4.5vw;
    }


    &--package{

      margin-bottom: 40px;
      @include mq($until: lg){
        margin-bottom: 30px;
      }
    }
    @include mq($until: md){
      font-size: 2.5vw;
    }
    @include mq($until: sm){
      font-size: 2.8vw;
    }
    @include mq($until: xs){
      font-size: 18px;
      text-align: center;
      justify-content: center;

    }
    &--attraction{
      margin-bottom: 1vw;

      @include mq($until: lg){
        margin-bottom: 15px;
      }
    }
  }
  &__description{
    // text-align: center;
    p{
      @include mq($until: lg){
        font-size: rem(14px);
        text-align: start;
      }
    }

  }
  &__guest{
    display: flex;
    align-items: center;
    margin: 0 0 1vw 0;
    @include mq($until: lg){
      padding-left: 0;
    }
    img{
      margin-right: 10px;
    }
    span{
      color: $color-main ;
      font-weight: 600;
      font-size: 1.1vw;
      @include mq($until: xl){
        font-size: 1.3vw;
      }
      @include mq($until: lg){
        font-size: 1.6vw;
      }
      @include mq($until: sm){
        font-size: 2.3vw;
      }
    }
  }
  &__footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    @include mq($until: lg){
      margin-top: 2vw;
    }
    &--attraction{
      justify-content: flex-end;
    }
    &--package{
      justify-content: space-between;
      width: 100%;
      margin-top: unset;

    }
  }
  &__price{
    font-size: 1vw;
    color: $color-main;
    @include mq($until: xl){
      font-size: 1.1vw;
    }
    @include mq($until: lg){
      font-size: 2vw;
    }
    @include mq($until: sm){
      font-size: 2.5vw;
    }
  }
  &__more{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding-left: 20px;
    position: relative;
    right: -30px;
    transition: background-color .3s ease-in-out, opacity .3s ease-in-out;
    display: none;
    @include mq($until: xl) {
      padding-left: 0;
      span{
        display: none;
      }
    }
    @include mq($until: lg){
      right: -20px;
    }
    @include mq($until: xs){
      width: 30px;
    }
    &--attraction{
      padding-left: 0;
      opacity: 0;
      background-color: $color-bg;
      img{
        filter: invert(1);
      }
    }
    span{
      color: $color-main;
      font-weight: $fw-medium;
      font-style: italic;
      letter-spacing: 0.5px;
      @include mq($until: xxl){
        font-size: rem(14px);
      }
    }
    &--package{
      padding: 13px 21px ;
      border-radius: 5px;
      border: 1px solid $color-main;
     
      &:hover{
        background-color: $color-main;
        color:#fff;
      }
    }
  }

  &--package{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    // padding:  0 1vw 0 0;
    margin-bottom: 1vw;
    width: 100%;
    transition: all .4s ease-in-out;
    border-radius: 0;
    border-radius: 8px;
    background-color:  $package-card-hover;
    transition: all .4s ease-in-out;
    &:hover{
      background-color: $color-main;
    }
    @include mq($until: lg){
      // padding:  0 2vw 0 0;
      margin-bottom: 2vw;
    }
    @include mq($until: xs){
      flex-direction: column;
    }
    &:hover{
      -webkit-box-shadow: none; 
      background-color: unset;
    }
    a:first-child{
      border-radius: 8px;
    }
    &__img{
      height: 16vw;
      background-repeat: no-repeat;
      width: 50%;
      border-radius: 0;
      transition: all .5s ease-in-out;
      border-radius:0 8px 8px 0;
      @include mq($until: xl){
        height: 15vw;
      }
      @include mq($until: xs){
        width: 100%;
      }
    }

    .catalog-card__content{
      padding: 0;
      display: flex;
      justify-content: center;
      width: 50%;
      height: 100;
      padding-left: 3vw;
      transition: all .5s ease-in-out;
      @include mq($until: lg){
        padding-right: 3vw;
      }
    }
      &:hover {
        color: #fff;
        background-color:#fff;
        
      }
  }
  &__packlist{
    background-color:#fff;
    transition: all .4s ease-in-out;
    &:hover{
      background-color: $color-main-transparent;
      h4 {
        color: #fff;
      }
      span {
        color: #fff;
      }
    }
  }
}