
.top-image{
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: $color-site-bg;
    @include mq($until: md) {
        height: 60vh;
    }
    &__text{
        p{
            font-size: rem(18px);
            color:#fff;
        }
    }
    &__heading{
      color: rgb(255, 255, 255);
        text-align: left;
        font-size: 3.4vw;
        line-height: 1;
        text-shadow: 1px 1px 340px rgba(8, 8, 39, 0.637);
       
        @include mq($until: xl) {
            font-size: 4.4vw;
        }
        @include mq($until: lg) {
            font-size: 5vw;
        }
        @include mq($until: md) {
            font-size: 6.5vw;
        }
        @include mq($until: md) {
            font-size: 6.5vw;
        }
        @include mq($until: sm) {
            font-size: rem(38px);
        }
        @include mq($until: xs) {
            font-size: rem(34px);
    
        }
    }
    &__box-wrapper{
        width: 100%;
        height: 100%;
        position: relative;
    }
    &__header-box{
        display: grid;
        grid-gap: 20px;
        position: absolute;
        top: 55%;
        transform: translateY(-50%);
        // background-color: #fff;
        padding: 40px;
        max-width: 600px;
        @include mq($until: md) {
            padding: 30px;
        }
        @include mq($until: sm) {
            padding: 20px;
        }
        &--left{
            left: 0;
        }
        &--right{
            right: 0;
            max-width: 60%;
            @include mq($until: lg) {
                max-width: 65%;
            }
            @include mq($until: xs) {
                max-width:80%;
            }
        }
        &--border {
            background-color:$color-box;
            opacity: .9;
        }
    }
}