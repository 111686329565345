// .reservation{
// display: flex;
// justify-content: center;
// }

.top-section-wrapper{
  width: 100%;
  &__position{
    z-index: 10;
    position: absolute;
    top: 84.5vh;
    display: block;
    display: flex;
    justify-content: center;
    width: 100%;
  } 
}
.section-header {
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;

  @include mq($until: lg) {
    font-size: 14px;
    flex-direction: column;
    justify-content: center;
  }
}

.section-full-screen-image {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: flex-end;
  position: relative;

  @include mq($until: lg) {
    flex-direction: column;
    justify-content: center;
  }
}

.contact-section {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  min-height: 50vh;
  height: auto;
  padding-top: 5vw;
    background-color: $color-site-bg;
&--apartment{
  padding-top: 0;
  min-height: unset;
}
  @include mq($until: lg) {
    min-height: unset;
  }

  // @include mq($until: lg) {
  //   grid-template-columns: 1fr;
  //   grid-template-rows: 1fr 1.5fr;
  // }
  // @include mq($until: xs) {
  //   grid-template-rows: 1fr 1fr;
  // }

  h4{
    color: $color-font-dark;
  }

  &__map-container {
    width: 100vw;
    height: 34vw;

      @include mq($until: lg) {
        height: 50vw;
      }
      @include mq($until: md) {
        height: 100vw;
      }
    
  }

  &__social-media {
    display: flex;
    align-items: center;
  }

  .social-media__link {
    display: inline-block;
    margin-left: 10px;
    .svg-icon{
      fill: $color-font-dark;
      transition: fill .3s ease-in-out;
    }
    &:hover .svg-icon{
      fill: $color-main;
    }
  }

  &__contact {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 30px;
    padding: 30px 50px 30px 0;

    @include mq($until: xl) {
      grid-row-gap: 20px;
    }

    @include mq($until: lg) {
      grid-column-gap: 15px;
      grid-template-columns: repeat(2, min-content 1fr);
    }

    @include mq($until: sm) {
      grid-template-columns:  min-content 1fr;
    }
  }

  &__text {
    line-height: 1.8;

    &--email {
      display: flex;
      align-items: center;
      @include mq($until: lg) {
        grid-column: 4/5;
      }

      @include mq($until: sm) {
        grid-column: unset;
      }
    }
    &--phone{
      display: flex;
      align-items: center;
    }

    &--address {
      @include mq($until: lg) {
        grid-row: 1/3;
      }

      @include mq($until: sm) {
        grid-column: unset;
      }
    }
  }

  &__icon {
    width: 26px;

    img {
      width: 100%;
    }

    &--email {
      @include mq($until: lg) {
        grid-column: 3/4;
      }

      @include mq($until: sm) {
        grid-column: unset;
      }
    }

    &--address {
      @include mq($until: lg) {
        grid-row: 1/3;
      }

      @include mq($until: sm) {
        grid-column: unset;
      }
    }
  }
}

.section__background {
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $color-site-bg;

  @include mq($until: lg) {
    position: static;
    height: 55vw;
    width: 100%;
    background-position: center center;
  }

  @include mq($until: xs) {
    height: 75vw;
  }
}

.svg-icon{
  width: 28px;
  fill: $color-main;
  display: flex;
  align-items: flex-start;
}

