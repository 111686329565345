.attraction{
    padding-top: 21px;
    padding-bottom: 3vw;
}
.room-list{
    padding-top: 5vw;
    background-color:  $package-card-hover;
    min-height: 95.5vh;
    @include mq($from: xs, $until: lg){
        padding-top: 10vw;
    }
    @include mq($from: xs, $until: md){
        padding-top: 12vw;
    }
    @include mq($until: xs){
        padding-top: 65px;
    }
    &__heading{
        text-align: center;
        padding-bottom: 2vw;
        padding-top: 2vw;
        font-size: rem(45px);
        @include mq($until: lg){
            font-size: rem(40px);
        }
        @include mq($until: md){
            font-size: rem(34px);
        }
    }
}