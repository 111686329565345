@import "~bootstrap";
@import '~sass-mq';
@import '~bourbon';
@import '~leaflet';
@import "~slick-carousel/slick/slick";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";

@import 'utils/mixins';
@import 'utils/variables';
@import 'utils/reset';
@import 'utils/animation-delays';

@import 'base/typography';
@import 'base/global';

@import 'layout/header';
@import 'layout/footer';
@import 'layout/gallery';
@import 'layout/gallery-list';
@import 'layout/files-list';
@import 'layout/subsite';
@import 'layout/contact-data';
@import 'layout/contact-form';
@import 'layout/section';
@import 'layout/news-list';
@import 'layout/single-news';
@import 'layout/news-card';

@import 'components/menu-dropdown';
@import 'components/menu-mobile';
@import 'components/header-box';
@import 'components/buttons';
@import 'components/cookie-bar';
@import 'components/catalog-list';
@import 'components/catalog-card';
@import 'components/slider';
@import 'components/simplelightbox';
@import "components/apartment-card-sub";

@import 'pages/404';
@import 'pages/home';
@import 'pages/pakiet-pobytowy';
@import 'pages/atrakcja';
@import 'pages/apartamenty';
@import 'pages/apartament';

@import 'pages/home/hotres-and-common-top-styles';
@import 'pages/home/topSection/image';
@import 'pages/home/topSection/slider';
@import 'pages/home/topSection/video';
@import 'pages/home/fullWidthImage/full-width-image';
@import 'pages/home/textWithImage/text-width-image';
@import 'pages/home/sliderSection/slider-section';
@import 'pages/home/iconSection/icons';
@import 'pages/home/callToAction/call-to-action';
@import 'pages/home/photoimageLink/photoLink';
