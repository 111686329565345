.apartment{

  &__slider{
    position: relative;
    z-index: 1;
    .slick-slide img{
      opacity: 0.3;
      transition: opacity .1s ease-in-out;
      height: 52vw;
      object-fit: cover;
      width: 100vw;
      @include mq($until: md){
        height: 50vw;
      }
      @include mq($until: sm){
        height: 55vw;
      }
    }
    .slick-current img{
      opacity: 1;
    }
  }
  &__slide{
    position: relative;
    outline: none;
  }
  &__arrows{
    position: relative;
    width: 100%;
    margin: 0 auto;
    bottom: 6px;
    @include mq($until: md){
      bottom: 5px;
    }
  }
  &__content{
    
    padding-top: 1.5vw;
    padding-bottom: 1vw;
    top: -10px;
    position: relative;

    display: flex;
    justify-content: space-between;

    @include mq($until: lg){
      padding-top: 3vw;
      padding-bottom: 2vw;
    }
    @include mq($until: md){
      flex-wrap: wrap;
    }
  }
  &__photo{
    background-color: #000;
    width: 100%;
    // max-width: 1325px;
  }
  &__header{
    grid-area: header;
    color:$color-main;
    font-size: rem(34px);
    text-transform: uppercase;
    font-weight: $fw-semi-bold;
    margin: 0;
    @include mq($until: xxl){
      font-size: rem(28px);
    }
    @include mq($until: xs){
      font-size: rem(24px);
    }
  }
  &__price{
    grid-area: price;
    color:$color-main;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mq($until: xs){
      flex-direction: column;
      align-items: flex-start;
    }
    span{
      font-weight: $fw-semi-bold;
      font-size: rem(21px);
      color: $color-main;
      @include mq($until: xxl){
         font-size: rem(18px);
      }
      @include mq($until: lg){
        font-size: rem(21px);
      }
      @include mq($until: xs){
        margin-bottom: 15px;
      }
    }
  }
  &__button{
    width: 200px;
    min-width: unset;
    font-size: rem(16px);
  }
  &__description{
    grid-area: description;
    max-width: 50%;
    @include mq($until: lg) {
      max-width: 99%;
      margin: auto;
    }
    p{
      margin-bottom: 0;
      line-height: 1.8;
      font-size: .7vw;
      @include mq($until: xl){
        font-size: 1.1vw;
      }
      @include mq($until: lg){
        font-size: 1.5vw;
      }
      @include mq($until: md){
        font-size: rem(18px);
      }
      @include mq($until: sm){
        font-size: rem(20px);
      }
    }
  }
  &__amenities{
    grid-area: amenities;
    padding: 3vw;
    display: grid;
    background-color: $package-card-hover;
    grid-template-columns: 1fr 1fr;
    // grid-auto-rows: 1vw;
    grid-gap: 3vw;
    height: fit-content;
    max-width: 48%;
    @include mq($until: lg){
      grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
      max-width: unset;
      // margin-right: 13px;
    }
    @include mq($until: md){
      grid-template-columns: repeat(auto-fit, minmax(18%, 1fr));
      grid-gap: 5vw;
    }
    @include mq($until: sm){
      grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
    }
    @include mq($until: xs){
      grid-gap: 30px;
      margin-bottom: 30px;
    }
  }
  &__informations{
    display: flex;
    justify-content: space-between;
    padding-top: 1vw;
    @include mq($until: lg) {
      flex-direction: column;
    }
  }
}
.amenity{
  display: flex;
  align-items: center;
  &__icon{
    margin-right: 20px;
    max-width: 50px;
  }
  &__name{
    font-size: rem(14px);
    font-weight: $fw-regular;
  }
}

.reserve{
  background-color: $color-main;
  padding-top: 3vw;
  margin-top: 5vw;
  border-radius: none;

  @include mq($until: sm) {
    padding-top: 8vw;
  }
    .hotresChooser {
     background-color: $package-card-hover;
    }
}