.header{
    top: 0;
    width: 100%;
    z-index: 9;
    position: relative;
    background-color: #fff;
    &--subsites{
        // background-color: $color-main-transparent;
        background-color: #fff;
        position: absolute;
    }
    &--subpage{
        background-color: #fff;
    }
    &--main{
        position: absolute;
        background-color:unset;
        padding-top: 13px;
    }
    &--apartment{
        background-color: #fff;
        position: absolute;
 
        .header__logo {
            max-width: 160px;
            @include mq($until: lg){
                max-width:130px;
            }
            @include mq($until: md){
                max-width:80px;
            }
        }
    }


    &--default{
        position: relative;
        background-color:none;
    }
    @include mq($until: md) {

        padding-top: 3vw;
    }
    &--fixed {
        position: fixed;
        transition: background-color .3s ease-in-out;
        &.sticky{
          background-color: rgba(255, 255, 255, 0.938);
        }
    }
&__wrapper{
    display: flex;

    justify-content: space-between;
    align-items: center;
}
    &--absolute {
        position: absolute;
    }

    &__logo{
      display: flex;
      align-items: center;
    }

    &__menuList{
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        margin: 0;
    }
    &__menuItem{
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 25px;
        padding: 0;
        &::before{
            display: none;
        }
    }
    &__menuLink{
        color: $color-main;
        font-size: rem(18px);
        font-weight: 500;
        padding-top: 13px;
        padding-bottom: 13px;
        transition: all .4s ease-in-out;
        @include mq($until: md) {
            border-bottom: 1px solid rgba(255, 255, 255, 0);
            transition: all .3s;

        }

        &:active, &--active, &--open{
            // color: $color-main;
            color:$color-extra;
            // opacity: .6;
            font-weight: bold;
        }

        &:hover {
            color:$color-extra;
            opacity: .6;
            @include mq($until: md) {
                color:$color-extra;
                border-bottom: 1px solid rgba(255, 255, 255, 1);
            }
        }
    }
 
    &__logo {
        max-width: 210px;
        z-index: 50;
        @include mq($until: xxl){
            max-width:11vw;
        }
        @include mq($until: lg){
            max-width:18vw;
        }
        @include mq($until: md){
            max-width:160px;
        }
        @include mq($until: sm){
            max-width:130px;
        }

    }
    &__atrakcja{  

            background-color: #fff;
            position: absolute;
            width: 100%;
    }
}


