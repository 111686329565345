// =========================
// Modules and Variables
// =========================

/**
    mq() takes up to three optional parameters:

    $from: inclusive min-width boundary
    $until: exclusive max-width boundary
    $and: additional custom directives
 */
$mq-breakpoints: (
        xs:  576px,
        sm:  768px,
        md:  992px,
        lg: 1200px,
        xl: 1500px,
        xxl: 1920px
);

$color-main:#5067a7;
// $color-main:#9a6f42;
// $color-main:#987b3d;
// $color-main:#ca6a1e;
// $color-main:#fab700;
// $color-main:#64778b;
// $color-main:#2c3e71;
$color-main-transparent:#5067a7c9;
$color-extra: #f2b610;
$color-icons: #fab700;
$color-font-light: #fff;
$color-font-dark: #5f5f5f;
$color-box: #ffffff;
$color-shadow: #5f5f5f52;
$color-bg: #f8f8f8;
$color-bg-extra: #fff;
$icons-bg: #fff;
$package-card-hover: #f8f7f3;
$color-site-bg: #f8f8f8;

//button colors
$color-text-button: #2c3e71 ;
$color-bg-button:#fff ;
$color-bord-button:#fff ;
$color-hover-button:#fff;

// Font weights
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-extra-bold: 700;
$fw-black: 900;


// Font Family
$font-family: 'Montserrat', serif;
$font-family-header: 'Merriweather','Montserrat', serif;


// Base Font for HTML
$base-font-size: 16px;

// Paragraph Styles
$paragraph-color      : $color-font-dark;
$paragraph-size       : 16px;
$paragraph-line-height: 1.5em;
$paragraph-margin     : 20px;

// Header Styles
$header-color      : $color-main;
$header-size       : 30px;
$header-line-height: 1.25em;
$header-margin     : $paragraph-margin * 1.2;

// Blockquote Styles
$blockquote-color      : rgba(85, 85, 85, 1);;
$blockquote-size       : 23px;
$blockquote-line-height: 1.5em;
$blockquote-margin     : $paragraph-margin * 1.5;

// Links
$link-color: rgba(85, 85, 85, 1);

// hr
$hr-margin: 30px;

// Type Misc
$font-weight-bold: 700;
$border-color: #DADADA;