.apartment-head{
  width: 100%;
  padding-top: 10vw;
  padding-bottom: 2vw;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  font-weight: 300;
  color: grey;
  font-size: 1.8vw;
  @include mq($until: lg){
    font-size: 3vw;
  }
  &--attraction{
    padding-top: 1vw;
  }
}

.catalog-list{
  display: flex;
  flex-direction: column;
  display: grid;
 grid-template-columns: repeat( auto-fit, minmax(28%, 1fr) );
  grid-row-gap: 3vw;
  grid-column-gap: 1.3vw;
  padding-bottom: 5vw;
  @include mq($until: lg){
    padding-bottom: 8vw;
    grid-template-columns: repeat(auto-fit, minmax(34%, 1fr));
    grid-row-gap: 3.4vw;
    grid-column-gap: 3.4vw;
  }
  &__bg{
    
    &--1{
      background-image: url("../../images/bg-apartment.jpg");
      background-repeat: no-repeat;
      background-position: center ;
      background-size: cover;
      height: auto;
    }
    &--2{
      background-image: url("../../images/bg-atraction.png");
      background-repeat: no-repeat;
      background-position: center ;
      background-size: cover;
      // width: 100vw;
      height: auto;
    }
  }


  @include mq($until: md){
    grid-template-columns: 1fr;
    grid-row-gap: 21px;
  }

  &__packages{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 70%;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    margin-bottom: 0;
    padding-bottom: 0;
    grid-row-gap: 0;
    background-color: #fff;
    padding: 0vw 1vw 0vw 1vw;
    @include mq($until: lg){
      padding: 0vw 2vw 0vw 2vw;
    }
    &--list{
      background-color:unset;
    }
    // a:last-child{
    //   padding: 0 1vw 1vw 0;
    //   @include mq($until: lg){
    //     padding: 0 2vw 2vw 0;
    //   }
    // }
  }

  .headwrapper{
    padding: 2vw 2vw 2vw 3vw;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    .heading{
      color: $color-main;
      margin-bottom: 0;

    }
    a{
      color: $color-main;
      &:hover{
        color:$color-extra;
      }
    }
  }
}

.packages-wrapper{
  padding-top: 5vw ;
  padding-bottom: 5vw ;
  border-radius: 8px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  @include mq($until: lg){
    padding-top: 8vw ;
    padding-bottom: 8vw ;
  }
  @include mq($until: md){
    flex-direction: column;
    align-items: center;
  }
  &__price-list{
    justify-content: space-between;
    background-color:$color-main;
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
    min-width: 25%;
    width: 100%;
    height: 100%;
    padding: 2vw;
    // margin: 0 1vw 0 1vw;
      background-image:url("../../images/bedroom.jpg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-blend-mode:soft-light, darken;
      width: inherit;
      height: inherit;
      filter: brightness(130%);

      @include mq($until: lg){
      //  margin-bottom: 8vw;
       padding: 3vw;
      }
      .priceTag{
        z-index: 2;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        background-blend-mode: unset;
        h3{
          margin-bottom: 0;
        }
      }
    
  
  }
  .separator{
    opacity: .18;
    margin: 1vw 0;
  }

  h2, h3, p{
    color: #fff;
  }
}
