.subsite {
  margin-bottom: 10px;
  // padding-top: 2vw;
  &__header {
    width: 100%;
    height: 30vw;
    display: flex;
    align-items: flex-end;
    margin-bottom: 40px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom -5vw center;

    @include mq($until: lg) {
      height: 28vw;
    }

    @include mq($until: xs) {
      height: 40vw;
    }

    div {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      display: flex;
      align-content: center;

      h1 {
        margin: 0;
        width: 100%;
        // color: $color-main-transparent;
        color: #fff;
        font-size: 3vw;
        // max-width: 8vw;
        display: flex;
        align-items: flex-end;
        line-height: 1;
        padding-bottom: 3vw;
        height: 100%;
        padding-left: 0;
        text-shadow: 4px 4px 55px rgb(0, 0, 0);
      }
    }
  }
}