.text-image{
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 5vw;
    padding-bottom: 1vw;
    background-color: #f8f8f8;
    padding-top: 8vw;
    padding-bottom: 8vw;
    @include mq($until: lg) {
        padding-top: 13vw;
        padding-bottom: 13vw;
    }
    &__bg{
        position: absolute;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 100%;
        width: 100%;
        opacity: .1;
    }
    &__wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 5vw;
        position: relative;
        @include mq($until: sm) {
            grid-template-columns: 1fr;
        }
        .button{
            margin: 0 auto;
        }
    }
    &__text-box{
        display: grid;
        grid-gap: 20px;
        align-content: flex-start;
        justify-content: flex-start;
        order: 2;
        @include mq($until: sm) {
            text-align: center;
            justify-content: center;
        }
    }
    &__image-box{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        order: 3;
        img{
            border-radius: 13px;
        }
        &.align{
            &__left{
                order: 1;
            }
            &__right{
                order: 3;
            }
        }
    }
}